import {Middleware} from "redux";
import {apiClient} from "utils/clients";
import {handleSingleAction} from "utils/actions";

const appMiddleware: Middleware = ()  => next => async action => {
    const { type, payload } = action;
    const response = next(action);

    if (type === "USER/LOAD-CONFIG") {
        return handleSingleAction(action, () => {
            return apiClient.get(`${import.meta.env.VITE_WLM_BACKEND_URL}/config/v1/user-config`);
        })
    } else if (type === "USER/SAVE-JOB-DESCRIPTION") {
        return handleSingleAction(action, () => {
            const { jobDescription } = payload;
            return apiClient.post(`${import.meta.env.VITE_WLM_BACKEND_URL}/config/v1/user-config`, {
                config: {
                    jobDescription: jobDescription.map((item: any) => item.label)
                }
            });
        })
    }

    return response;
}
export default appMiddleware;