import {useRef, useState, useMemo} from "react";
import {css} from '@emotion/react';
import {Transition} from "react-transition-group";
import styles from "./MainNav.module.scss"
import classNames from "classnames";
import {ReactComponent as DatabasesIcon} from "./workloadicons/databases.svg";
import {ReactComponent as HomeIcon} from "./workloadicons/home.svg";
import {ReactComponent as StorageIcon} from "./workloadicons/storage.svg";
import {ReactComponent as AIIcon} from "./workloadicons/ai.svg";
import {ReactComponent as BuilderIcon} from "./workloadicons/builder.svg";
import {ReactComponent as EdaIcon} from "./workloadicons/eda.svg";
import {ReactComponent as EpicIcon} from "./workloadicons/epic.svg";
import {ReactComponent as GeneralFilesIcon} from "./workloadicons/generalfiles.svg";
import {ReactComponent as K8SIcon} from "./workloadicons/k8s.svg";
import {ReactComponent as SAPIcon} from "./workloadicons/sap.svg";
import {ReactComponent as VMWareIcon} from "./workloadicons/vmware.svg";
import {NavLink} from "react-router-dom";
import {Tag} from "ui-components";
import {useSelector} from "react-redux";
import {RootState} from "store";
import _ from "lodash";

const workloadToIconMap: { [key: string]: any } = {
    "databases": DatabasesIcon,
    "ai": AIIcon,
    "builders": BuilderIcon,
    "eda": EdaIcon,
    "epic": EpicIcon,
    "generalfiles": GeneralFilesIcon,
    "k8s": K8SIcon,
    "sap": SAPIcon,
    "vmware": VMWareIcon
}

const Item = ({to, label, Icon, isComingSoon}: { to?: string, label: string, Icon: any, isComingSoon?: boolean }) => {
    const Component = isComingSoon ? "button" : NavLink;
    return <Component to={to || ""} className={styles.item} disabled={isComingSoon}>
        <div className={styles.icon}>
            <Icon/>
        </div>
        <div className={styles.label}>{label}</div>
        {isComingSoon && <Tag color="chart-1" style={{margin: "0 0 0 auto"}} className={styles.tag}>Coming soon</Tag>}
    </Component>
}

const NoIcon = () => <div/>;

const MainNav = () => {
    const ref = useRef(null);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const closeRef: { current: { timeout: ReturnType<typeof setTimeout> | null } } = useRef({timeout: null});
    const workloadConfigs = useSelector((state: RootState) => state.services.workloadConfigs);

    const navItems = useMemo(() => {
        return _.orderBy(workloadConfigs.filter(config => config.hasMenuItem), ["nav.isComingSoon", "name"], ["desc", "asc"]);
    }, [workloadConfigs])

    return <Transition nodeRef={ref} in={isMenuOpen} timeout={400}>
        {state => {
            return <div
                className={classNames(styles.container, styles[state])}
                onMouseEnter={() => {
                    if (closeRef.current.timeout) {
                        clearTimeout(closeRef.current.timeout);
                        closeRef.current.timeout = null;
                    } else {
                        setMenuOpen(true);
                    }
                }}
                onMouseLeave={() => {
                    closeRef.current.timeout = setTimeout(() => {
                        closeRef.current.timeout = null;
                        setMenuOpen(false);
                    }, 200)
                }}
            >
                <Item label="Home" to="/" Icon={HomeIcon}/>
                <Item label="Storage" to="/wlmfsx" Icon={StorageIcon}/>
                {navItems.map(workloadConfig => {
                    const Icon = workloadToIconMap[workloadConfig.id] || NoIcon;
                    return <Item key={workloadConfig.id} label={workloadConfig.name} to={workloadConfig.to} Icon={Icon}
                                 isComingSoon={workloadConfig.nav?.isComingSoon}/>
                })}
            </div>
        }}
    </Transition>
}

export default MainNav;