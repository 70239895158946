import {Middleware} from "redux";
import {externalClient} from "utils/clients";
import {handleSingleAction} from "utils/actions"
import {validate} from "modules/integrations/validator";

const appMiddleware: Middleware = ({dispatch}) => next => async action => {
    const {type, payload} = action;
    const response = next(action);

    if (type === "APP/BOOT") {
        dispatch({
            type: "APP/BOOT:SUCCESS"
        });
        dispatch({
            type: "AUTH/INITIALIZE",
            payload: {
                domain: import.meta.env.VITE_AUTH0_DOMAIN,
                clientId: import.meta.env.VITE_AUTH0_CLIENT,
                audience: import.meta.env.VITE_AUTH0_AUDIENCE
            }
        });
        dispatch({
            type: "APP/LOAD-WORKLOADS"
        });
    } else if (type === "APP/MINI-BOOT") {
        dispatch({
            type: "APP/MINI-BOOT:SUCCESS"
        });
        dispatch({
            type: "APP/LOAD-WORKLOADS"
        });
    } else if (type === "APP/BOOT:FAILED") {
        dispatch({
            type: "NOTIFICATIONS:ERROR",
            payload: {
                message: "Failed to initialize UI.",
                exception: payload,
                isFatal: true
            }
        })
    } else if (type === "APP/RELOAD") {
        window.location.reload();
    } else if (type === "APP/LOAD-WORKLOADS") {
        return handleSingleAction(action, async () => {
            const {data: { services: workloads }} = await externalClient.get("/services/services.json")

            const allWorkloads = await Promise.all(workloads.map(async (workload: any) => {
                try {
                    const { data: config } = await externalClient.get(`${import.meta.env.DEV ? "/workloads" : "" }/${workload.prefix}workload-config.json`)

                    const validationResult = validate(config);

                    if(validationResult.success) {
                        return {...validationResult.data, prefix:workload.prefix}
                    } else {
                        console.error(`Config file for workload with prefix, ${workload.prefix}, and id: ${config?.id} failed validation`, validationResult.error)
                        return null;
                    }
                } catch (e) {
                    console.error(`Failed downloading workload config file with prefix, ${workload.prefix}. Workload will be dropped from UI.`, e)
                    return null;
                }
            }))

            return allWorkloads.filter(config => !!config);
        }).catch(e => {
            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    message: "Failed to download workload list (services.json). UI unable to render.",
                    exception: e,
                    isFatal: true
                }
            })
            throw e;
        });
    }

    return response;
}
export default appMiddleware;