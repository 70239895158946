import {Auth0Error, CheckSessionOptions} from "auth0-js";

const randomString = function(length: Number) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

class Auth {
    private auth: any;
    constructor(opts: {clientID: string, audience: string, domain: string}){
        if(!opts.clientID) throw new Error("Missing clientID from opts");
        if(!opts.audience) throw new Error("Missing audience from opts");

        this.auth = new window.auth0.WebAuth(Object.assign({
            domain: "netapp-cloud-account.auth0.com",
            redirectUri: window.location.origin,
            responseType: 'token id_token',
            scope: 'openid profile email',
            leeway: 30
        }, opts));

        this.loginRedirect = this.loginRedirect.bind(this);
        this.refreshSso = this.refreshSso.bind(this);
        this.logout = this.logout.bind(this);
    }

    loginRedirect(auth0Opts?: Object, paramsForLoginPage?: Object, paramsForUI?: Object){
        const extra = paramsForLoginPage ? `?paramsForLoginPage=${window.btoa(JSON.stringify(paramsForLoginPage))}` : "";
        this.auth.authorize(Object.assign({state: window.btoa(JSON.stringify(Object.assign({rand: randomString(30)}, paramsForUI))), redirectUri: `${window.location.origin}/${extra}`}, auth0Opts));
    }

    refreshSso(auth0Opts?: CheckSessionOptions, paramsForUI?: Object, dontRedirectErrors?: Boolean){
        const authenticate = (err, authResult, resolve, reject, firstTime) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                try {
                    authResult.paramsForUI = JSON.parse(window.atob(authResult.state));
                } catch (e) {

                }
                resolve(authResult); //success case
            }
            else{
                //case err == null: (happens for very specific errors - auth0 doesn't generate err obj) fill with 'unknown error'
                if (!err) err = {error: "unknown error", errorDescription: "Unknown error. Try to refresh or contact support"};

                //case 'login required': set needLogin flag to true and reject err obj. Caller should then call loginRedirect
                if (err.error === "login_required") {
                    err.needLogin = true;
                    reject(err);
                } else if(dontRedirectErrors || (firstTime && (err.errorDescription === "Nonce does not match." || err.errorDescription === "`state` does not match."))){
                    reject(err);
                } else{
                    //for all other errors - redirect to NetApp SaaS Portal Error Page with included error description
                    window.location.href = `${import.meta.env.VITE_PORTAL_FRONTEND}/error-page?error=${err.error}&error_description=${err.errorDescription}`;
                }
            }
        };

        const renewOnce = (firstTime: Boolean) => {
            return new Promise((resolve, reject) => {
                this.auth.checkSession(Object.assign({
                    state: window.btoa(JSON.stringify(Object.assign({rand: randomString(30)}, paramsForUI)))
                }, auth0Opts), (err?: Auth0Error, authResult?: any) => {
                    authenticate(err, authResult, resolve, reject, firstTime);
                });
            })
        };

        const parseHashOnce = () => {
            return new Promise((resolve, reject) => {
                this.auth.parseHash((err, authResult) => {
                    authenticate(err, authResult, resolve, reject, true)
                });
            })
        };

        return new Promise((resolve, reject) => {
            let refreshPromise = null;
            if(window.location.hash && (window.location.hash.indexOf("access_token") > 0 || window.location.hash.indexOf("error") > 0)){
                if(window.location.href.indexOf("#!/#access_token") !== -1) window.history.pushState({}, null, window.location.href.replace("#!/"));
                refreshPromise = parseHashOnce()
            } else {
                refreshPromise = renewOnce(true)
            }

            return refreshPromise.catch(err => {
                if(err.errorDescription === "Nonce does not match." || err.errorDescription === "`state` does not match."){
                    return renewOnce(false);
                } else throw err;
            }).then(resolve, reject)
        });
    }

    logout(opts?: any){
        this.auth.logout(Object.assign({returnTo: window.location.origin, federated: true}, opts))
    }
}

export default Auth;