import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {WorkloadConfig} from "./validator";

export default (): WorkloadConfig | undefined => {
    const location = useLocation();
    const workloadConfigs = useSelector((state: RootState) => state.services.workloadConfigs);

    const servicesByTo = useMemo(() => {
        return _.keyBy(workloadConfigs, "to")
    }, [workloadConfigs]);

    const firstSegment = location.pathname.split("/")[1];
    return servicesByTo["/" + firstSegment];
}
