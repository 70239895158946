import {ActionType} from 'store'

const defaultState = () => ({
    appState: "loading"
});


export default (state = defaultState(), { type }: ActionType): any => {
    switch (type) {
        case "APP/BOOT:SUCCESS": {
            return { ...state, appState: "ready", isIframe: false }
        }
        case "APP/MINI-BOOT:SUCCESS": {
            return { ...state, appState: "ready", isIframe: true }
        }
        case "APP/BOOT:FAILED": {
            return { ...state, appState: "failed" }
        }
        case "APP/RELOAD": {
            return { ...state, isReloading: true }
        }
        case "APP/LOAD-WORKLOADS:ERROR": {
            return { ...state, appState: "failed" }
        }
        default: {
            return state;
        }
    }
};
