import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import store,{history} from 'store';
import {Provider} from "react-redux";
import {
    unstable_HistoryRouter as HistoryRouter,
    Link,
    Route,
    Routes,
    NavLink
} from "react-router-dom";
import auth0 from "auth0-js";
import { setLinkProvider, initModalTargetListener } from "ui-components";
import "./modules/app/configureYup"
import { ThemeContextController } from "modules/app/ThemeContext";
import "ui-components/dist/index.css"

setLinkProvider({ Link, NavLink });
if(window.top === window) {
    window.auth0 = auth0;

    store.dispatch({
        type: "APP/BOOT"
    });
} else {
    store.dispatch({
        type: "APP/MINI-BOOT"
    });
}

history.listen(() => {
    window.scrollTo(0, 0);
});

initModalTargetListener();

const RedirectApiDoc = () => {
    window.location.replace(`${window.location.origin}/api-doc#pathname=${window.location.pathname.replace("/api-doc", "")}`);
    return <div/>
}

const RedirectStyleGuide = () => {
    window.location.replace(`${window.location.origin}/styleguide/index.html?path=${window.location.pathname}`)
    return <div/>
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
         <Provider store={store}>
             <HistoryRouter history={history}>
                 <ThemeContextController>
                     <Routes>
                         <Route path="/api-doc/*" element={<RedirectApiDoc/>}/>
                         <Route path="/styleguide/*" element={<RedirectStyleGuide/>}/>
                         <Route path="*" element={<App/>}/>
                     </Routes>
                 </ThemeContextController>
             </HistoryRouter>
         </Provider>
    </React.StrictMode>
)