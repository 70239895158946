import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {UserSettingsIcon} from "ui-icons/react";
import {DetailsItem, WidgetButton} from "./MainHeaderMenuWidgetItems";
import {Button, Span, Toggle, StyledLink} from "ui-components";
import {useDispatch} from "react-redux";

import {css} from '@emotion/react'

const logoutItemCss = css`
  height: 80px;
  padding: 24px;
  background-color: var(--content-background);
`;

const LogoutItem = () => {
    const dispatch = useDispatch();

    return <div css={logoutItemCss}>
        <Button style={{width: 128}} onClick={() => {
            dispatch({
                type: "AUTH/LOGOUT"
            })
        }}>Log out</Button>
    </div>
};

const darkThemeToggleCss = css`
  padding: 24px;
  background-color: var(--content-background);
`;

const DarkThemeToggle = () => {
    const dispatch = useDispatch();
    const isActive = useSelector((state: RootState) => !!state.features.active["Platform.BlueXP/DarkTheme"]);

    return <div css={darkThemeToggleCss}>
        <div style={{marginBottom: 8}}>
            <Span style={{marginRight: 8}}>Dark Theme</Span>
        </div>
        <Toggle value={isActive} toggle={() => {
            dispatch({
                type: "FEATURES/TOGGLE",
                payload: {
                    feature: "Platform.BlueXP/DarkTheme",
                    noReload: true
                }
            });
        }}/>
    </div>
}

const UserWidget = () => {
    const name = useSelector((state: RootState) => state.auth.name);
    const company = useSelector((state: RootState) => state.auth.company);
    const email = useSelector((state: RootState) => state.auth.email);
    const isNetAppInternal = useSelector((state: RootState) => state.auth.isNetAppInternal);

    return <WidgetButton Icon={UserSettingsIcon} label={name}  ariaLabel="User settings">
        <DetailsItem label="User name" value={name}/>
        <DetailsItem label="Email" value={email}/>
        <DetailsItem label="Company" value={company}>
            {isNetAppInternal && <StyledLink to="/netapp-internal" variant="text" style={{ position: "absolute", right: 40, marginTop: 15, zIndex: 1 }}>NetApp Internal</StyledLink>}
        </DetailsItem>
        <DarkThemeToggle/>
        <LogoutItem/>
    </WidgetButton>
}

export default UserWidget