import {extractErrorMessage} from "utils/errors";
import { successNotificationTimeout, infoNotificationTimeout } from "../AppConstants";
import {Middleware} from "redux";
import {RootState} from "store";

/**
 *

 {
     message - main line of the notification (shown before moreInfo is clicked)
     exception - optional, if present, will print to console log and message of the exception is shown in moreInfo
     moreInfo - optional (will overwrite message from exception if both are present)
     group - all errors of same group will be killed if a component with useErrorContext is removed
     key - if present the error is not shown globally, allows for manual placement/erasure of errors
     if key is present and not group, then group becomes key
     type -> error/warning, success/info
     isFatal -> cant be closed
 }

 */
const notificationMiddleware: Middleware<RootState> = ({getState, dispatch}) => next => async action => {
    const {type, payload} = action;

    if(type === "NOTIFICATIONS:ERROR" || type === "NOTIFICATIONS:WARNING") {
        const { isFatal, message, moreInfo: _moreInfo, exception, group, key, showGlobal } = payload;

        if(exception) {
            console.error(message);
            console.error(exception);
        }

        let moreInfo = _moreInfo;

        if(exception && !_moreInfo) {
            moreInfo = extractErrorMessage(exception);
        }

        if(key) {
            const existingNotification = getState().notifications.byKey[key];
            if(existingNotification) {
                dispatch({
                    type: "NOTIFICATIONS:REMOVE",
                    payload: {
                        notificationId: existingNotification.notificationId
                    }
                })
            }
        }

        dispatch({
            type: "NOTIFICATIONS:ADD",
            payload: {
                type: type === "NOTIFICATIONS:ERROR" ? "error" : "warning",
                isFatal,
                description: message,
                moreInfo,
                notificationId: crypto.randomUUID(),
                group,
                key,
                showGlobal
            }
        })
    } else if (type === "NOTIFICATIONS:INFO" || type === "NOTIFICATIONS:SUCCESS") {
        const { message, group, key, showGlobal } = payload;
        const isSuccess = type === "NOTIFICATIONS:SUCCESS";

        const notificationId = crypto.randomUUID();

        dispatch({
            type: "NOTIFICATIONS:ADD",
            payload: {
                type: isSuccess ? "success" : "info",
                description: message,
                notificationId,
                group,
                key,
                showGlobal
            }
        })

        setTimeout(() => {
            dispatch({
                type: "NOTIFICATIONS:REMOVE",
                payload: {
                    notificationId
                }
            })
        }, isSuccess ? successNotificationTimeout : infoNotificationTimeout)
    }

    return next(action);
}
export default notificationMiddleware