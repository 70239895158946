import store from "store";
import _ from "lodash";

type Type = string;

interface Action {
    type: Type
    payload: any
}

const isAction = (data: any): data is Action => {
    return _.isObject(data);
}

export const handleSingleAction = (actionOrType: Action | Type, promiseF: () => Promise<any> ) => {
    let type = actionOrType;
    if(isAction(actionOrType)) {
        type = actionOrType.type;
    }

    store.dispatch({
        type: `NOTIFICATIONS:KILL-GROUP`,
        payload: {
            group: type
        }
    });

    return handlePromiseAction(actionOrType, promiseF);
}

export const handlePromiseAction = async (actionOrType: Action | Type, promiseF: () => Promise<any>) => {
    let type = actionOrType;
    let request: Action | undefined = undefined;
    const requestId = crypto.randomUUID();
    if(isAction(actionOrType)) {
        type = actionOrType.type;
        request = actionOrType;
    }

    store.dispatch({
        type: `${type}:PENDING`,
        originalType: type,
        request,
        requestId
    });

    try {
        const response = await promiseF();
        store.dispatch({
            type: `${type}:SUCCESS`,
            originalType: type,
            requestId,
            payload: response,
            request
        })

        return response;
    } catch (e) {
        store.dispatch({
            type: `${type}:ERROR`,
            originalType: type,
            requestId,
            payload: e,
            request
        })

        throw e;
    }
}
