import { useMemo } from "react";
import {Modal, ModalContent, ModalHeader, ModalFooter, Button, Text, FormFieldSelect, useForm} from "ui-components";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import _ from "lodash";
import { closeModal } from "ui-utils/useModal"

const SwitchAccountModal = () => {
    const dispatch = useDispatch();
    const accounts = useSelector((state: RootState) => state.tenancy.accounts);
    const selectedAccount = useSelector((state: RootState) => state.tenancy.selectedAccount);

    const accountOptions = useMemo(() => {
        return _.map(accounts, account => {
            const forbidden = account.userRole !== "Role-1" && account.userRole !== "Role-2"
            return {
                value: account.accountPublicId,
                label: account.accountName,
                isDisabled: forbidden,
                tooltip: forbidden ? "You have no permissions to connect to this account" : undefined
            }
        })
    }, []);

    const form = useForm({ account: { value: selectedAccount?.accountPublicId, label: selectedAccount?.accountName } });

    return <Modal>
        <ModalHeader>
            Switch account
        </ModalHeader>
        <ModalContent>
            <Text style={{marginBottom: 32}}>Select the account that you want to use.</Text>
            <FormFieldSelect
                form={form}
                isSearchable={true}
                name="account"
                label="Account"
                options={accountOptions}
                menuPortalTarget={document.body}
            />
        </ModalContent>
        <ModalFooter>
            <Button color="primary" onClick={() => {
                if(form.formState.account.value === selectedAccount?.accountPublicId) {
                    closeModal();
                } else {
                    dispatch({
                        type: "TENANCY/SET-ACCOUNT",
                        payload: {
                            accountId: form.formState.account.value
                        }
                    })
                }
            }}>Switch</Button>
            <Button color="secondary" onClick={closeModal}>Close</Button>
        </ModalFooter>
    </Modal>
}

export default SwitchAccountModal;