import { z } from "zod";

export const workloadValidator = z.object({
    name: z.string(),
    id: z.string(),
    to: z.string().startsWith("/"),
    bluexpTo: z.string().optional(),
    logo: z.string().startsWith("<svg").default("<svg viewBox=\"157 120.502 104 104\" xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"157\" y=\"120.502\" width=\"104\" height=\"104\" style=\"stroke: rgb(0, 0, 0); fill: rgb(6, 89, 247);\"/></svg>"),
    hasPolicies: z.boolean().optional(),
    hasMenuItem: z.boolean().optional(),
    apiDocUrl: z.string().optional().describe("relative path starting after /accounts/:id"),
    stageIntegrations: z.array(z.object({
        path: z.string().startsWith("/"),
        renderType: z.enum(["iframe/v2", "webcomponent/v1"]),
        iframeUrl: z.string().optional(),
        url: z.string().optional()
    })).optional(),
    homepage: z.object({
        description: z.string(),
        isComingSoon: z.boolean().optional(),
        dataUrl: z.string(),
        primaryCtaDropdownTo: z.array(z.object({
            to: z.string(),
            bluexpTo: z.string().optional(),
            label: z.string(),
            isDisabled: z.boolean().optional(),
            disabledTooltip: z.string().optional()
        })).optional(),
        primaryCtaDropdownLabel: z.string().optional(),
        primaryCtaTo: z.string().optional(),
        primaryCtaBluexpTo: z.string().optional(),
        primaryCtaLabel: z.string().optional(),
        secondaryCtaTo: z.string(),
        secondaryCtaBluexpTo: z.string().optional(),
        secondaryCtaLabel: z.string(),
        featureId: z.string().optional()
    })
        .optional(),
    nav: z.object({
        isComingSoon: z.boolean().optional()
    }).optional(),
    features: z.array(z.object({
        title: z.string(),
        id: z.string(),
        isAnon: z.boolean().optional(),
        isAlwaysOn: z.boolean().optional()
    })).optional()
});

export type WorkloadConfig = z.infer<typeof workloadValidator>;

export const validate = (config: any) => {
    return workloadValidator.safeParse(config);
}
