import app from "./reducers/appReducer";
import tenancy from "./reducers/tenancyReducer";
import auth from "./reducers/authReducer";
import notifications from "./reducers/notificationsReducer";
import operations from "./reducers/operationsReducer";
import modal from "./reducers/modalReducer";
import features from "./reducers/featuresReducer";
import services from "./reducers/servicesReducer";
import user from "./reducers/userReducer";
import appMiddleware from "./middlewares/appMiddleware";
import authMiddleware from "./middlewares/authMiddleware";
import tenancyMiddleware from "./middlewares/tenancyMiddleware";
import notificationsMiddleware from "./middlewares/notificationsMiddleware";
import userMiddleware from "./middlewares/userMiddleware";

export const createStaticReducers = () => ({
    app,
    auth,
    tenancy,
    notifications,
    operations,
    modal,
    features,
    services,
    user
});

export const createStaticMiddlewares = () => ([
    appMiddleware,
    authMiddleware,
    tenancyMiddleware,
    notificationsMiddleware,
    userMiddleware
]);
