import React, {useMemo} from "react";
import useIframeV2 from "./useIframev2";
import {MainLoader, Notification, Layout} from "ui-components";
import useCurrentService from "./useCurrentService";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import {css} from '@emotion/react'
import RenderWebComponent from "./RenderWebComponent";

const iframeCss = css`
  padding: 0;
  margin: 0;
  border: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: var(--main-background);
`;

const RenderIframeV2 = React.memo(({iframeUrl}: { iframeUrl: string }) => {
    const {isInitialized, iframeRef, src} = useIframeV2({iframeUrl})

    return <>
        {!isInitialized && <MainLoader/>}
        <iframe key={src} title="service" src={src} ref={iframeRef} css={iframeCss}
                style={{opacity: !isInitialized ? 0 : undefined}}/>
    </>
})

const ServiceIntegration = React.memo(() => {
    const currentService = useCurrentService();
    const location = useLocation();

    const stageIntegration = useMemo(() => {
        if (currentService) {
            return _.find(currentService.stageIntegrations, (stageIntegration) => {
                const suffix = stageIntegration.path === "/" ? "" : stageIntegration.path;
                return location.pathname.startsWith(currentService.to + suffix)
            });
        }
    }, [location.pathname, currentService]);

    if(stageIntegration && stageIntegration.renderType === "webcomponent/v1") {
        return <RenderWebComponent id={currentService?.id} url={stageIntegration.url}/>
    } else if (stageIntegration && stageIntegration.renderType === "iframe/v2") {
        return <RenderIframeV2 iframeUrl={stageIntegration.iframeUrl}/>
    } else {
        return <Layout.Page>
            <Layout.Content>
                <Layout.Container>
                    <Notification type="warning" style={{width: "max-content", margin: "0 auto"}}>
                        No stage integration match for current service and path.
                    </Notification>
                </Layout.Container>
            </Layout.Content>
        </Layout.Page>
    }
})

export default ServiceIntegration;