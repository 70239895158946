import _ from "lodash";
import {ActionType} from "store";

const defaultState = () => ({
    notifications: [], byKey: {}, byId: {}, byGroup: {}
});

type NotificationsState = {byGroup:{[key: string]: NotificationType[] | undefined}, notifications:NotificationType[] | undefined, byId:any, byKey:any}

type NotificationType = {key: string, id:string}

const handleClearGroup = (group: string, state:NotificationsState) => {

    const notificationsGroup = state.byGroup[group];

    if (notificationsGroup) {
        const newNotifications = _.reject(state.notifications, { group });
        const newByGroup = { ...state.byGroup, [group]: undefined };
        const newById = { ...state.byId };
        const newByKey = { ...state.byKey };

        _.forEach(notificationsGroup, notification => {
            delete newById[notification.id];
            if (notification.key) {
                delete newByKey[notification.key];
            }
        });

        return { ...state, notifications: newNotifications, byGroup: newByGroup, byKey: newByKey, byId: newById }
    } else {
        return { ...state }
    }
}

export default (state:NotificationsState = defaultState(), { type, payload }:ActionType): NotificationsState => {
    switch (type) {
        case "NOTIFICATIONS:ADD": {
            const { key, showGlobal } = payload;
            let newByKey = state.byKey;
            let newByGroup = state.byGroup;
            let newNotifications = state.notifications;

            let group = payload.group;

            if (key && !group) {
                group = key;
            }

            const notification = { ...payload, group };

            if (!key || showGlobal) {
                newNotifications = state.notifications.concat(notification);
            }

            if (group) {
                const newGroup = (state.byGroup[group] || []).concat(notification);
                newByGroup = { ...state.byGroup, [group]: newGroup };
            }

            if (key) {
                newByKey = { ...state.byId, [key]: notification };
            }

            const newById = { ...state.byId, [payload.notificationId]: notification };

            return { ...state, notifications: newNotifications, byKey: newByKey, byId: newById, byGroup: newByGroup };
        }
        case "NOTIFICATIONS:REMOVE": {
            const { notificationId, key } = payload;
            let newById = state.byId;
            let newByKey = state.byKey;
            let newByGroup = state.byGroup;
            const newNotifications = notificationId ? _.reject(state.notifications, { notificationId }) : _.reject(state.notifications, { key });

            const notification = notificationId ? state.byId[notificationId] : state.byKey[key];

            if (notification) {
                newById = { ...state.byId };
                delete newById[notificationId];
            }

            if (notification?.key) {
                newByKey = { ...state.byKey };
                delete newByKey[notification.key];
            }

            if (notification?.group) {
                const newGroup = _.reject(state.byGroup[notification.group], { notificationId: notificationId });

                newByGroup = { ...state.byGroup, [notification.group]: newGroup };

                if (newGroup.length === 0) {
                    delete newByGroup[notification.group];
                }
            }

            return { ...state, notifications: newNotifications, byKey: newByKey, byId: newById, byGroup: newByGroup }
        }
        case "NOTIFICATIONS:KILL-GROUP": {
            const { group } = payload;
            return handleClearGroup(group, state);
        }
        case "CLEAR-CONTEXT": {
            const { context } = payload;

            const allGroups = Object.keys(state.byGroup);

            let updatedState:NotificationsState = state;
            for (const group of allGroups) {
                if(group.startsWith(context)) {
                    updatedState = handleClearGroup(group, updatedState);
                }
            }

            return updatedState;
        }
        default: {
            return state;
        }
    }
};
