import Auth from "utils/auth/Auth";
import querystring from "query-string";
import store, {RootState, history } from "store";
import {setAccessToken} from "utils/clients";
import _ from "lodash";
import {setUserId} from "utils/localStorage";
import {Middleware} from "redux";
import {Auth0Result} from "auth0-js";
import {setFullStory} from "../../../utils/analytics";

const handleLocationReplacements = (authResult: { accessToken: string; expiresIn: string; paramsForUI: { pathname: string; } }): void => {
    const search = querystring.parse(window.location.search);
    const hash = querystring.parse(window.location.hash);

    if(hash.redirectMessage) {
        store.dispatch({
            type: "NOTIFICATIONS:SUCCESS",
            payload: {
                message: hash.redirectMessage
            }
        })
    }

    if (authResult.paramsForUI.pathname) { // pathname received from login page. Navigate to it.
        const path = authResult.paramsForUI.pathname.startsWith('/') ? authResult.paramsForUI.pathname : `/${authResult.paramsForUI.pathname}`;
        history.replace(path);
    } else {
        delete search.redirectToLogin;
        delete search.paramsForLoginPage;
        delete hash.redirectMessage;
        const hashString = querystring.stringify(hash);
        const searchString = querystring.stringify(search);
        history.replace(window.location.pathname + (searchString ? `?${searchString}` : '') + (hashString ? `#${hashString}` : ''));
    }
};

let auth: Auth;
const authMiddleware: Middleware<RootState> = ({dispatch, getState}) => next => async (action) => {
    const {type, payload} = action;
    const response = next(action);

    if (type === "AUTH/INITIALIZE") {
        const {domain, clientId: clientID, audience} = payload;

        dispatch({
            type: 'AUTH/INITIALIZE:PENDING'
        })

        auth = new Auth({
            clientID,
            domain,
            audience
        });

        window.__auth = auth;

        dispatch({
            type: "AUTH/REFRESH-SSO"
        });
    } else if (type === "AUTH/REFRESH-SSO") {
        const loginSuccess = (authResult: Auth0Result) => {
            const userId = _.get(authResult, "idTokenPayload.sub", "");
            setAccessToken(authResult.accessToken);
            setUserId({userId});
            setFullStory(authResult);

            dispatch({
                type: "AUTH/REFRESH-SSO:SUCCESS",
                payload: authResult
            });

            setTimeout(() => {
                dispatch({
                    type: "AUTH/RENEW-SSO"
                });
            }, (authResult.expiresIn || 7200) * 1000 - 35000);

            setTimeout(() => {
                dispatch({
                    type: "AUTH/AUTO-LOGOUT"
                });
            }, 3 * 60 * 60 * 1000);

            handleLocationReplacements(authResult);
        };

        const loginFail = (error: any) => {
            dispatch({
                type: "AUTH/REFRESH-SSO:FAILED",
                payload: error
            });
        };

        auth.refreshSso().then(loginSuccess, loginFail);
    } else if (type === "AUTH/REFRESH-SSO:FAILED") {
        auth.loginRedirect({}, {}, {pathname: window.location.pathname + window.location.search});
    } else if (type === "AUTH/LOGOUT") {
        auth.logout();
    } else if (action.type === "AUTH/RENEW-SSO") {
        const loginSuccess = (authResult: any) => {
            setAccessToken(authResult.accessToken);

            const currentUserId = _.get(getState(), "auth.userId");
            const newUserId = _.get(authResult, "idTokenPayload.sub");

            if (currentUserId !== newUserId) {
                console.log("Newly logged in user is different from previous.. reloading app..");
                window.location.reload();
            }

            dispatch({
                type: "AUTH/REFRESH-SSO:SUCCESS",
                payload: authResult
            });

            setTimeout(() => {
                dispatch({
                    type: "AUTH/RENEW-SSO"
                });
            }, authResult.expiresIn * 1000 - 35000);

        };

        const loginFail = (err: { error: string; }) => {
            if (err.error === "timeout") {
                dispatch({
                    type: "AUTH/RENEW-SSO:TIMEOUT"
                });
            } else {
                dispatch({
                    type: "AUTH/REFRESH-SSO:FAILED"
                });
            }
        };

        auth.refreshSso().then(loginSuccess, loginFail);
    } else if (type === "AUTH/AUTO-LOGOUT") {
        auth.logout();
    }

    return response;
}
export default authMiddleware