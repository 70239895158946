let userId = "anon";
let tenancyId = "-";

type ItemType =  {itemKey:string, includeTenancyId?: boolean, userId?: string}
const generateKey = ({ itemKey, userId, includeTenancyId }: { itemKey?: string, userId?: string, includeTenancyId?: boolean
}) => includeTenancyId ? `occm.${itemKey}.${userId}.${tenancyId}` : `occm.${itemKey}.${userId}`;

const get = (k: string) => {
    try {
        const item = window.localStorage.getItem(k)
        if(!item){
            return null;
        }
        return JSON.parse(item);
    } catch (e) {
        return null;
    }
}

const set = (k: string, v: any) => {
    window.localStorage.setItem(k, JSON.stringify(v));
}

const remove = (k: string) => {
    window.localStorage.removeItem(k);
}

export const getItem = ({ itemKey, includeTenancyId }: ItemType) => {
    return get(generateKey({ itemKey, userId, includeTenancyId }));
};

export const setItem = ({ itemKey, includeTenancyId } : ItemType, value: any) => {
    set(generateKey({ itemKey, userId, includeTenancyId }), value);
};

export const removeItem = ({ itemKey, includeTenancyId }: ItemType) => {
    remove(generateKey({ itemKey, userId, includeTenancyId }));
};

export const getItemAnon = ({ itemKey }: ItemType) => {
    return get(generateKey({ itemKey, userId: "anon" }));
};

export const setItemAnon = ({ itemKey }: ItemType, value : any) => {
    set(generateKey({ itemKey, userId: "anon" }), value);
};

export const removeItemAnon = ({ itemKey }: ItemType) => {
    remove(generateKey({ itemKey, userId: "anon" }));
};

export const setUserId = ({ userId: _userId }: {userId:string}) => {
    userId = _userId;
};

export const setTenancyId = ({ tenancyId: _tenancyId }: {tenancyId:string}) => {
    tenancyId = _tenancyId;
}