import _ from "lodash";
import {ActionType} from "store";
import {setAccessToken} from "utils/clients.ts";
import {setUserId} from "utils/localStorage.ts";

interface AuthState {
    isAuthenticated: boolean
    isInitializing: boolean
    userId?: string
    name?: string
    company?: string
    email?: string
    accessToken?: string
    idTokenPayload?: any
    isEmailVerified?: boolean
    firstName?: string
    isFederatedUser?: boolean
    scopes?: any
    isNetAppInternal?: boolean
    status: string
}

const defaultState: () => AuthState = () => ({
    isAuthenticated: false,
    isInitializing: false,
    status: "UNSET"
});

const extractFirstName = (fullName: string) => (fullName || "").trim().split(" ")[0];

export default (state = defaultState(), {type, payload}: ActionType): AuthState => {
    switch (type) {
        case "AUTH/REFRESH-SSO:SUCCESS": {
            const isNetAppInternal = payload.idTokenPayload["http://cloud.netapp.com/internal"] === "NetApp";
            const name = payload.idTokenPayload["http://cloud.netapp.com/user_metadata"].name || "";
            const firstName = extractFirstName(name);
            const email = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email');
            const company = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].company');
            const scopeArr = (payload.scope || "").split(" ");
            const userId = _.get(payload, "idTokenPayload.sub");
            const isFederatedUser = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/is_federated"]', false);
            const {accessToken, idTokenPayload} = payload;

            if (state.isAuthenticated && state.userId !== userId) {
                console.log("Refresh SSO returned different logged in user, re-starting app");
                window.location.reload();
            }

            return {
                ...state,
                status: "SUCCESS",
                isAuthenticated: true,
                userId,
                accessToken,
                idTokenPayload,
                isEmailVerified: payload.idTokenPayload['email_verified'],
                firstName,
                name,
                isFederatedUser,
                scopes: _(scopeArr).keyBy().mapValues(_.constant(true)).value(),
                isNetAppInternal,
                email,
                company
            };
        }
        case "AUTH/MINI-BOOT": {
            const isNetAppInternal = payload.userMetadata["http://cloud.netapp.com/internal"] === "NetApp";
            const name = payload.userMetadata["http://cloud.netapp.com/user_metadata"].name || "";
            const firstName = extractFirstName(name);
            const email = _.get(payload, 'userMetadata["http://cloud.netapp.com/user_metadata"].email');
            const company = _.get(payload, 'userMetadata["http://cloud.netapp.com/user_metadata"].company');
            const scopeArr = (payload.scope || "").split(" ");
            const userId = _.get(payload, "userMetadata.sub");
            const isFederatedUser = _.get(payload, 'userMetadata["http://cloud.netapp.com/is_federated"]', false);
            const {accessToken, userMetadata} = payload;
            setAccessToken(payload.accessToken);
            setUserId({userId});
            return {
                ...state,
                ...payload,
                status: "SUCCESS",
                isAuthenticated: true,
                userId,
                accessToken,
                idTokenPayload: userMetadata,
                isEmailVerified: payload.userMetadata['email_verified'],
                firstName,
                name,
                isFederatedUser,
                scopes: _(scopeArr).keyBy().mapValues(_.constant(true)).value(),
                isNetAppInternal,
                email,
                company
            };
        }
        case "AUTH/INITIALIZE:PENDING": {
            return {...state, isInitializing: true, status: "PENDING"};
        }
        default: {
            return state;
        }
    }
};
