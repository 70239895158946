import React from "react";
import {ThemeProvider} from "ui-components";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {getItemAnon} from "../../../../jonter/src/utils/localStorage";

const isDarkTheme = getItemAnon({itemKey: "feature.Platform.BlueXP/DarkTheme"}) || false;

export const ThemeContextController = React.memo(({children}: { children: any }) => {
    const isActive = useSelector((state: RootState) => state.features.isInitialized ? !!state.features.active["Platform.BlueXP/DarkTheme"] : isDarkTheme);

    return <ThemeProvider theme={isActive ? "dark" : "light"} isRoot={true}>
        {children}
    </ThemeProvider>
})

