import React, {useEffect, useMemo} from "react";
import { getUnixTime } from 'date-fns'
import { useSelector } from "react-redux";
import _ from "lodash";
import { apiClient } from 'utils/clients';
import { RootState } from 'store';
import {useLocation} from "react-router-dom";

const style = window.document.createElement('style');
style.type = 'text/css';
window.document.head.appendChild(style);

const loadIntercom = _.once(() => {
    if (import.meta.env.VITE_INTERCOM_ID) {
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', window.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args)
                };
                w.Intercom = i;

                function l() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + import.meta.env.VITE_INTERCOM_ID;
                    var x = d.getElementsByTagName('script')[0];
                    setTimeout(function () {
                        x.parentNode.insertBefore(s, x)
                    }, 3000);
                }

                if(w.__loaded) {
                    l()
                } else {
                    if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    } else {
                        w.addEventListener('load', l, false);
                    }
                }
            }
        })()
    } else {
        window.Intercom = () => {};
    }
});

const hide = () => {
    style.innerHTML = `.intercom-container, .intercom-lightweight-app, .intercom-app {
        display: none !important;
    }`;
};

const show = () => {
    style.innerHTML = ``;
};

 const Intercom = () => {
    const { email, userId, status, isAuthenticated, name } = useSelector((state: RootState) => state.auth);
     const appState = useSelector((state: RootState) => state.app.appState);
    const accountSerial = useSelector((state: RootState) => state.tenancy.selectedAccount?.accountSerial)
    const accountPublicId = useSelector((state: RootState) => state.tenancy.selectedAccount?.accountPublicId)

    useEffect(() => {
        // useEffect if we ever want to hide the intercom
        show();
    }, []);
    useEffect(() => {
        if (appState !== "loading") {
            loadIntercom();
        }
    }, [appState]);

    useEffect(() => {
        (async () => {
            if ((status !== "PENDING" && status !== "UNSET")) {
                if (isAuthenticated) {
                    /**
                     * Get the user hash corresponding of the userId.
                     */
                    const url = `${import.meta.env.VITE_PLATFORM_BACKEND_URL}/v1/NetApp.Identity/login-identities/generateHash`;
                    const user_hash_res = await apiClient.get(url);
                    /**
                     * Let's initialize the intercom even if the user_hash is empty, because in future if we want to disable the identity verification in intercom js, then we don't have to make any changes in the code again, with empty user_hash it should work. And when identity verification is enabled, if user_hash is not defined, the chat won't work.
                     */
                    window.Intercom('boot', {
                        app_id: import.meta.env.VITE_INTERCOM_ID,
                        email: email,
                        user_id: userId,
                        created_at: getUnixTime(new Date()), //Returns Unix timestamp (in seconds)
                        user_hash: user_hash_res?.data?.userHash,
                        name
                    });
                } else if (email) {
                    window.Intercom('boot', {
                        app_id: import.meta.env.VITE_INTERCOM_ID
                    });
                }
            }
        })();
    }, [email, userId, status, isAuthenticated, appState]);

    useEffect(() => {
        if (accountSerial) {
            window.Intercom('update', {
                "Account Serial": accountSerial,
                "Account public ID": accountPublicId
            })
        }
    }, [accountSerial, accountPublicId])

    return <></>;
};

export default Intercom;