import {ActionType} from 'store'
import _ from "lodash";

interface UserState {
    jobDescription: string[] | null
    isJobDescriptionLoaded: boolean
}

const defaultState: () => UserState = () => ({
    jobDescription: null,
    isJobDescriptionLoaded: false
});

export default (state: UserState = defaultState(), {type, payload}: ActionType): UserState => {
    switch (type) {
        case "USER/LOAD-CONFIG:SUCCESS": {
            const isJobDescriptionValid = _.isArray(payload.data?.config?.jobDescription) && _.every(payload.data.config.jobDescription, _.isString);
            return {...state, jobDescription: isJobDescriptionValid ? payload.data.config.jobDescription : null, isJobDescriptionLoaded: true}
        }
        case "USER/LOAD-CONFIG:ERROR": {
            //Treat error as if user has set empty job description so they dont see pointless error message
            return {...state, jobDescription: null, isJobDescriptionLoaded: true}
        }
        default: {
            return state;
        }
    }
};
