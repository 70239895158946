import _ from "lodash";

interface DataWithMessage {
    message: string
}

interface DataWithStringError {
    error: string
}

interface DataWithErrorInMessage {
    error: DataWithMessage
}

const hasMessage = (data: any): data is DataWithMessage => {
    return _.isString(data.message);
}

const hasStringError = (data: any): data is DataWithStringError => {
    return _.isString(data.error);
}

const hasDataWithErrorInMessage = (data: any): data is DataWithErrorInMessage => {
    return _.isString(data.error?.message);
}

export const extractErrorMessage = (e: any): string => {
    const data: any = e?.response?.data;
    if (_.isString(data)) {
        return data;
    } else if (_.isObject(data)) {
        if (hasMessage(data)) {
            return data.message;
        } else if (hasStringError(data)) {
            return data.error;
        } else if (hasDataWithErrorInMessage(data)) {
            return data.error.message
        } else {
            return "Unknown"
        }
    } else if (hasMessage(e)) {
        return e.message;
    } else if (_.isString(e)) {
        return e;
    } else {
        return "Unknown";
    }
};