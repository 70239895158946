import React from "react";
import { Text, Layout, TabHeader, Heading, Button } from "ui-components"
import { useLocation } from "react-router-dom";

class ErrorHandler extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }
    // TabHeader = () => <div/>
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        console.error(error);
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        return this.props.children !== nextProps.children || this.state.hasError !== nextState.hasError || this.state.hasError && this.props.pathname !== nextProps.pathname;
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (prevState.hasError && prevProps.pathname !== this.props.pathname) {
            this.setState({ hasError: false })
        }
    }

    render() {
        if (this.state.hasError) {
            return <Layout.Page>
                <TabHeader label="Failed to Render"/>
                <Layout.Content style={{ padding: "40px 0" }}>
                    <Layout.Column>
                        <Heading level={2}>
                            The tab or service has unexpectedly crashed.
                        </Heading>
                        <Text>
                            Error printed to console.
                        </Text>
                        <Text>If the error recurs please contact Support.</Text>

                        {import.meta.env.MODE === "development" && <Button color="secondary" onClick={() => {
                            this.setState({ hasError: false })
                        }}>
                            Reset
                        </Button>}
                    </Layout.Column>
                </Layout.Content>
            </Layout.Page>;
        }

        return this.props.children;
    }
}

const MainErrorBoundary = React.memo(({ children }) => {
    const location = useLocation();

    return <ErrorHandler pathname={location.pathname}>
        {children}
    </ErrorHandler>
});

export default MainErrorBoundary;