import styles from "./MainHeader.module.scss"
import {Heading, Tag} from "ui-components";
import HelpMenu from "./HelpMenu"
import AccountMenu from "./AccountMenu"
import UserMenu from "./UserMenu"
import {Link} from "react-router-dom"
import {ReactComponent as MainLogo} from "./MainLogo.svg";

import {css} from '@emotion/react';
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

const productNameCss = css`
  display: flex;
  align-items: center;
`;

const Widgets = ({isFirstTimeUser}: { isFirstTimeUser: boolean }) => {
    return <div className={styles.widgets}>
        {!isFirstTimeUser && <>
            <HelpMenu/>
            <AccountMenu/>
        </>}
        <UserMenu/>
    </div>
}


const MainHeader = ({isFirstTimeUser}: { isFirstTimeUser: boolean }) => {
    const isDemo = useSelector((state: RootState) => state.tenancy.isDemo);

    return <div className={styles.base}>
        <div css={productNameCss}>
            <Link to="/" css={productNameCss}>
                <MainLogo style={{marginRight: 16}}/>
                <Heading level="16" bold style={{marginRight: 16}}>Workload Factory</Heading>
            </Link>
            {isDemo && <Tag color="chart-1">Demo</Tag>}
        </div>

        <Widgets isFirstTimeUser={isFirstTimeUser}/>
    </div>
}

export default MainHeader;