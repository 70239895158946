import { get } from "lodash";

export const setFullStory = authResult => {
    setFullStoryUserValues({
        "internal": get(authResult,"idTokenPayload['http://cloud.netapp.com/internal']"),
        "isNetappDomain": get(authResult, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email','').toLowerCase().endsWith("netapp.com"),
        "userId": get(authResult, "idTokenPayload.sub", "")
    })
}

export const setFullStoryUserValues = (values) => {
    try{
        window["FS"].setUserVars(values);
    } catch(e) {
        console.log("Failed to set full story values");
        console.log(values);
        console.log(e);
    }

}
