import {HelpIcon, RssIcon} from 'ui-icons/react';
import {WidgetButton, MenuExternalLinkItem, MenuButtonItem} from './MainHeaderMenuWidgetItems';
import useModal, {openModal} from 'ui-utils/useModal';
import FeedbackModal from './FeedbackModal.tsx';
import {useSelector} from "react-redux";
import {RootState} from "../../../store.ts";

const HelpWidget = () => {
  const isFeedbackModalOpen = useModal('MARKETING/FEEDBACK').isActive;
  const isRssActive = useSelector((state: RootState) =>  !!state.features.active["Shell/Support"]);
  return <>
    <WidgetButton Icon={HelpIcon} ariaLabel="Help">
      <MenuExternalLinkItem href={"https://docs.netapp.com/us-en/workload-family"}>Documentation</MenuExternalLinkItem>
      <MenuExternalLinkItem href={import.meta.env.VITE_WLM_FRONTEND_URL + "/api-doc"}>API Hub</MenuExternalLinkItem>
      <MenuExternalLinkItem href={"https://github.com/NetApp/FSx-ONTAP-samples-scripts/tree/main/Monitoring"}>Monitoring GitHub repository</MenuExternalLinkItem>
      <MenuExternalLinkItem href={import.meta.env.VITE_BLUEXP_FRONTEND + "/support-dashboard"}>Support</MenuExternalLinkItem>
      {isRssActive && <MenuExternalLinkItem icon={<RssIcon style={{marginLeft:10}}/>} href={"https://docs.netapp.com/us-en/workload-relnotes/whats-new.html"}>Subscribe to RSS</MenuExternalLinkItem>}
      <MenuButtonItem onClick={() => {
        openModal('MARKETING/FEEDBACK')
      }}>Feedback</MenuButtonItem>
    </WidgetButton>
    {isFeedbackModalOpen && <FeedbackModal/>}
  </>
}

export default HelpWidget;
