import {create} from 'zustand'
import {useShallow} from 'zustand/react/shallow'

interface ModalState {
    activePayload?: any
    active?: string
    openModal: (key: string, payload?: any) => void
    closeModal: () => void
}

const useModalStore = create<ModalState>((set, get) => ({
    openModal: (key: string, payload?: any) => {
        set({activePayload: payload, active: key})
    },
    closeModal: () => {
        set({activePayload: undefined, active: undefined})
    }
}))

const useModal = (key: string) => {
    return useModalStore(useShallow(state => {
            const isActive = state.active === key;
            return {
                isActive, payload: isActive ? state.activePayload : undefined
            };
        }
    ))
}

export const openModal = useModalStore.getState().openModal;
export const closeModal = useModalStore.getState().closeModal;

export const getActivePayload = () => useModalStore.getState().activePayload;
export default useModal;