import _ from "lodash";
import {Link} from "react-router-dom";
import styles from "./MainHeader.module.scss";
import {ClickAway, Span, fadeClassNames, ExternalLink} from "ui-components";
import React, {useState, useRef, ForwardedRef} from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import {css} from '@emotion/react'

const detailsItemCss = css`
  background-color: var(--content-background);
  height: 92px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const DetailsItem = ({value, label, children}: { value?: string, label: string } & React.PropsWithChildren) => {
    return <div css={detailsItemCss}>
        {children}
        <Span level={13} bold ellipsis>{value}</Span>
        <Span level={13}>{label}</Span>
    </div>
}

export const MenuLinkItem = ({to, children, close}: { to: string, children: any, close?: any}) => {
    return <Link to={to} className={styles.link} onClick={close}><Span level={13} bold>{children}</Span></Link>
}

export const MenuExternalLinkItem = ({href, children, close, icon}: { href: string, children: any, close?: any, icon?:any}) => {
    return <ExternalLink variant="none" href={href} className={styles.link} onClick={close} includeArrow={!icon}><Span level={13} bold>{children}</Span>{icon}</ExternalLink>
}

export const MenuButtonItem = ({onClick, children, close}: { close: any, children: any, onClick: any}) => {
    return <button onClick={(e) => {
        close();
        onClick(e);
    }} className={styles.button}><Span level={13} bold>{children}</Span></button>
}

const WidgetMenu = React.memo(React.forwardRef(({close, children}: { close: () => {}, children: any }, forwardRef ) => {
    return <div className={styles.menu} ref={forwardRef}>
        <ClickAway listener={close} ignoreParentClick capture/>
        {_.map(_.isArray(children) ? children : [children], (child, index) => child && React.cloneElement(child, {
            key: index,
            close
        }))}
    </div>
}))

export const WidgetButton = ({Icon, label, children, ariaLabel }: { Icon: any, label?: string, children: any, ariaLabel: string }) => {
    const [isOpen, setIsOpen] = useState(false);
    const nodeRef = useRef<HTMLDivElement | null>(null);

    return <div className={styles.widgetButtonWrapper}>
        <button aria-label={ariaLabel} className={classNames(styles.widgetButton, {[styles.active]: isOpen})} onClick={() => {
            setIsOpen(true);
        }}>
            <Icon/>
            {label && <Span ellipsis>{label}</Span>}
        </button>
        <CSSTransition nodeRef={nodeRef} in={isOpen} unmountOnExit timeout={400} classNames={fadeClassNames}>
            <WidgetMenu ref={nodeRef} close={() => setIsOpen(false)}>{children}</WidgetMenu>
        </CSSTransition>
    </div>
}