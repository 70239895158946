import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import _ from "lodash";
import {RootState} from "store";

interface InitializationData {
    userMetadata: any
    pathSuffix?: string
    accessToken: string
    accountId: string
    isDemoMode: boolean
    pathname: string
    hash: string
    search: string
    domain: string
    features: any
}

export default ({iframeUrl}: { iframeUrl: string }) => {
    const iframeRef = useRef(null);
    const isReadyRef = useRef(false)
    const [readyState, setReadyState] = useState(null);
    const initializationData: React.MutableRefObject<InitializationData | null> = useRef(null);
    const iframeOrigin = iframeUrl && new URL(iframeUrl, iframeUrl.startsWith("http") ? undefined : window.location.origin).origin;
    const navigate = useNavigate();

    const {idTokenPayload: userMetadata, accessToken} = useSelector((state: RootState) => state.auth);
    const {selectedAccountId: accountId, isDemo: isDemoMode} = useSelector((state: RootState) => state.tenancy);
    const location = useLocation();
    const dispatch = useDispatch();
    const features = useSelector((state: RootState) => ({
        byKey: state.features.byKey,
        active: state.features.active
    }), shallowEqual);

    initializationData.current = {
        userMetadata,
        ...location.state,
        accessToken: iframeUrl.startsWith("https://workloads.netapp.com") ? undefined : accessToken,
        accountId: accountId === "no-project" ? undefined : accountId,
        isDemoMode: isDemoMode || false,
        pathname: location.pathname,
        hash: location.hash,
        search: location.search,
        domain: window.location.host,
        features
    };

    const sendMessage = useCallback(({type, payload}) => {
        if (iframeRef.current && isReadyRef.current) {
            iframeRef.current.contentWindow.postMessage({
                type: `SERVICE:${type}`,
                payload
            }, iframeOrigin);
        }
    }, [iframeRef, iframeOrigin]);

    useEffect(() => {
        const receiveMessage = (event) => {
            if (!iframeRef.current || event.origin !== iframeOrigin || event.source !== iframeRef.current.contentWindow) {
                return;
            } else {
                if (_.isObject(event.data)) {
                    const {type, payload} = event.data;

                    switch (type) {
                        case "SERVICE:READY": {
                            setReadyState(crypto.randomUUID());
                            isReadyRef.current = true;
                            return;
                        }
                        case "SERVICE:NAVIGATE": {
                            if (payload.hash) {
                                navigate(`#${payload.hash}`, {replace: true})
                            } else {
                                navigate(payload.pathname, {state: payload.state, replace: payload.replace})
                            }
                            return;
                        }
                        case "SERVICE:NOTIFICATION": {
                            dispatch({
                                type: payload.type,
                                payload: {
                                    message: payload?.message,
                                    exception: payload?.exception,
                                    group: payload?.group,
                                    key: payload?.key
                                }
                            });
                            return;
                        }
                        default: {
                            console.log(`Parent APP received unknown message type - ${type}`);
                            return;
                        }
                    }
                }
            }
        };

        window.addEventListener("message", receiveMessage, false);

        return () => {
            window.removeEventListener("message", receiveMessage)
        }
    }, [iframeOrigin, navigate, dispatch, sendMessage]);

    useEffect(() => {
        if (readyState) {
            sendMessage({
                type: "ON-READY",
                payload: initializationData.current
            })
        }
    }, [sendMessage, readyState])

    useEffect(() => {
        sendMessage({
            type: "TOKEN-UPDATE",
            payload: {
                accessToken,
                userMetadata
            }
        })
    }, [sendMessage, accessToken, userMetadata]);


    const {pathname, hash, search} = location;
    useEffect(() => {
        sendMessage({
            type: "LOCATION-CHANGE",
            payload: {pathname, hash, search}
        })
    }, [sendMessage, pathname, hash, search]);

    useEffect(() => {
        sendMessage({
            type: "FEATURES-CHANGE",
            payload: {
                features
            }
        })
    }, [sendMessage, features])

    const src = useMemo(() => {
        return iframeUrl + (initializationData.current?.pathSuffix || '');
    }, [iframeUrl]);

    return useMemo(() => {
        return {isInitialized: !!readyState, iframeRef, src}
    }, [readyState, src])
};
