import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "store";
import {
    MainLoader,
    Notification,
    FeatureNotification,
    LocationNotification,
    Navigator,
    Integration
} from "ui-components";
import {css} from "@emotion/react";
import {useNavigate, useLocation} from "react-router-dom";

const serviceCss = css`
  height: 100%
`;

interface ComponentState {
    isInitializing: boolean
    isMounted: boolean
    shadowRoot: ShadowRoot | null
    integration: Integration | null
    error?: string
}

const RenderWebComponent = React.memo(({id, url}: { id: string, url: string }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isFailed, setIsFailed] = useState<boolean>(false);
    const location = useLocation();
    const stateRef = useRef<ComponentState>({
        isInitializing: false,
        isMounted: false,
        shadowRoot: null,
        integration: null,
        error: undefined
    });
    const {idTokenPayload: userMetadata, accessToken} = useSelector((state: RootState) => state.auth);
    const {selectedAccountId: accountId, isDemo: isDemoMode} = useSelector((state: RootState) => state.tenancy);
    const {isInitialized: isFeaturesInitialized} = useSelector((state: RootState) => state.features);
    const features = useSelector((state: RootState) => ({
        byKey: state.features.byKey,
        active: state.features.active
    }), shallowEqual);
    const navigate = useNavigate();

    useEffect(() => {
        if (!stateRef.current.isInitializing && !stateRef.current.isMounted && isFeaturesInitialized) {
            stateRef.current.isInitializing = true;
            (import.meta.env.DEV && window.__localIntegration && window.__localIntegration.id === id ? Promise.resolve(window.__localIntegration) : import(/* @vite-ignore */ url)).then((integration: Integration) => {
                const navigator: Navigator = ({
                                                  pathname,
                                                  hash,
                                                  replace,
                                                  state
                                              }: { pathname: string, hash?: string, replace?: boolean, state?: any }) => {
                    if (hash) {
                        navigate(`#${hash}`, {replace: true})
                    } else {
                        navigate(pathname, {state, replace: replace})
                    }
                }

                const initializationData = {
                    userMetadata,
                    accessToken,
                    accountId: accountId === "no-project" ? undefined : accountId,
                    isDemoMode: isDemoMode || false,
                    features,
                    navigator
                };

                if (typeof (integration.mount) == "function" && typeof (integration.unmount) == "function" && typeof (integration.notify) == "function") {
                    integration.mount(stateRef.current.shadowRoot as ShadowRoot, initializationData).then(() => {
                        stateRef.current.integration = integration;
                        stateRef.current.isInitializing = false;
                        stateRef.current.isMounted = true;
                        setIsInitialized(true);
                    });
                } else {
                    stateRef.current.error = `Service Initialization Failure - missing required function(s): ${["mount", "unmount", "notify"].map(name => (typeof integration?.[name] !== "function") ? name : undefined).filter(exists => !!exists).join(",")}`;
                    setIsFailed(true);
                }
            });
        }

        return () => {
            if (stateRef.current.isMounted) {
                stateRef.current.isMounted = false;
                setIsInitialized(false);
                stateRef.current.integration.unmount();
                stateRef.current.integration = null;
            }
        }
    }, [isFeaturesInitialized]);

    useEffect(() => {
        if (stateRef.current?.integration) {
            stateRef.current.integration.notify({
                type: "TOKEN-UPDATE",
                payload: {
                    accessToken,
                    userMetadata
                }
            })
        }
    }, [accessToken, userMetadata]);

    useEffect(() => {
        if (stateRef.current?.integration) {
            stateRef.current.integration.notify({
                type: "FEATURES-CHANGE",
                payload: {
                    features
                }
            } as FeatureNotification)
        }
    }, [features])

    useEffect(() => {
        if (stateRef.current?.integration) {
            stateRef.current.integration.notify({
                type: "LOCATION-CHANGE",
                payload: {
                    location
                }
            } as LocationNotification)
        }
    }, [location])

    return <>
        {!isInitialized && !isFailed && <MainLoader/>}
        {!isFailed && <div data-stage={id} css={serviceCss} ref={container => {
            if (container && !stateRef.current.shadowRoot) {
                stateRef.current.shadowRoot = container.attachShadow({mode: "open"});
            }
        }}/>}
        {isFailed && <div css={serviceCss}>
            <Notification type="error">{stateRef.current.error}</Notification>
        </div>}
    </>
})

export default RenderWebComponent;