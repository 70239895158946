import {ActionType} from 'store'
import _ from "lodash";
import {WorkloadConfig} from "../../integrations/validator";
import {localWorkloadsConfig} from '../AppConstants.ts'
import {setItemAnon, getItemAnon} from "utils/localStorage";

interface ServiceState {
    serviceIntegrationPaths: string[]
    workloadConfigs: WorkloadConfig[]
    isWorkloadsLoaded: boolean
    workloadConfigsMap: { [key: string]: WorkloadConfig }
    configOverrides?: any
}

const defaultState: () => ServiceState = () => ({
    serviceIntegrationPaths: [],
    workloadConfigs: [],
    isWorkloadsLoaded: false,
    workloadConfigsMap: {}
});

const applyOverrides = ({ overrides, configs }) => {
    const clonedConfigs = _.cloneDeep(configs);

    _.forEach(overrides, (value, key) => {
        const id = key.split(/\.(.*)/s)[0];
        const path = key.split(/\.(.*)/s)[1];
        const configToUpdate = _.find(clonedConfigs, {id});
        if (configToUpdate) {
            _.set(configToUpdate, path, value);
        }
    });

    const workloadConfigsMap = _.mapKeys(clonedConfigs, 'id');
    const serviceIntegrationPaths = _.map(clonedConfigs, "to");

    return {
        workloadConfigsMap,
        serviceIntegrationPaths,
        configs: clonedConfigs
    }
}

export default (state: ServiceState = defaultState(), {type, payload}: ActionType): ServiceState => {
    switch (type) {
        case "APP/LOAD-WORKLOADS:SUCCESS": {
            const defaultConfigs = _.uniqBy(payload.concat(localWorkloadsConfig), 'id');
            let configs = defaultConfigs;
            let defaultConfigsMap = _.mapKeys(defaultConfigs, 'id');
            let workloadConfigsMap = defaultConfigsMap;
            let serviceIntegrationPaths = _.map(configs, "to");

            const configOverrides = getItemAnon({itemKey: "configOverrides"});

            if(configOverrides) {
                const overridenConfigs = applyOverrides({ overrides: configOverrides, configs });
                workloadConfigsMap = overridenConfigs.workloadConfigsMap;
                serviceIntegrationPaths = overridenConfigs.serviceIntegrationPaths;
                configs = overridenConfigs.configs;
            }

            return {
                ...state,
                defaultConfigs,
                defaultConfigsMap,
                workloadConfigs: configs,
                isWorkloadsLoaded: true,
                serviceIntegrationPaths,
                workloadConfigsMap,
                configOverrides
            }
        }
        case "APP/WORKLOADS/UPDATE": {
            const {overrides} = payload;

            setItemAnon({itemKey: "configOverrides"}, overrides);
            const { workloadConfigsMap, serviceIntegrationPaths, configs } = applyOverrides({ overrides, configs: state.workloadConfigs });

            return {...state, workloadConfigs: configs, serviceIntegrationPaths, workloadConfigsMap};
        }
        default: {
            return state;
        }
    }
};
