import {useSelector} from "react-redux";
import {RootState} from "store";
import {AccountNameIcon} from "ui-icons/react";
import { WidgetButton, MenuLinkItem, MenuButtonItem } from "./MainHeaderMenuWidgetItems";
import SwitchAccountModal from "./SwitchAccountModal";
import useModal, { openModal } from "ui-utils/useModal"

const AccountWidget = () => {
    const accountName = useSelector((state: RootState) => state.tenancy.selectedAccount?.accountName);
    const isSwitchAccountModalOpen = useModal("TENANCY/SWITCH-ACCOUNT").isActive;

    if (!accountName) {
        return null;
    }

    return <>
        <WidgetButton Icon={AccountNameIcon} label={accountName}  ariaLabel="Account">
            <MenuLinkItem to="/credentials">Credentials</MenuLinkItem>
            <MenuLinkItem to="/links">Links</MenuLinkItem>
            <MenuButtonItem onClick={() => {
                openModal("TENANCY/SWITCH-ACCOUNT")
            }}>Switch account</MenuButtonItem>
        </WidgetButton>
        {isSwitchAccountModalOpen && <SwitchAccountModal/>}
    </>
}

export default AccountWidget;